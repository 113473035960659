import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {createAuthHeader, fetchUrl, formatDate, useFilter} from "../../utils/util";

export function AuftraegeAdmin() {
    const [jobs, setJobs] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchJobs() {
            try {
                const url = `${fetchUrl}/api/admin/jobs`;
                const response = await fetch(url,
                    {
                        headers: {
                            ...createAuthHeader(),
                        }
                    });

                if (response.ok) {
                    const data = await response.json();
                    setJobs(data);
                } else {
                    throw new Error('Fehler beim Laden der Jobs: ' + response.status);
                }
            } catch (error) {
                console.error(error);
            }
        }


        fetchJobs()
    }, []);

    const filterFunctions = {
        creator: (job, value) => job.auftraggeber.toLowerCase().includes(value.toLowerCase()),
        date: (job, value) => job.date.startsWith(value),
        noInterpreter: (job, value) => value ? job.interpreterId === null : true
    };

    const { filters, handleFilterChange, filteredItems: filteredJobs } = useFilter(jobs, filterFunctions);

    return (
        <div className="container mx-auto mt-8 px-4">
            <h1 className="text-2xl font-bold mb-8 text-center">Alle Aufträge</h1>
            <div className="mb-8 flex flex-col md:flex-row items-center justify-center gap-2 md:gap-4">
                <input
                    type="text"
                    name="creator"
                    placeholder="Ersteller"
                    value={filters.creator || ''}
                    onChange={handleFilterChange}
                    className="border p-2 rounded w-full md:w-auto"
                />
                <input
                    type="date"
                    name="date"
                    value={filters.date || ''}
                    onChange={handleFilterChange}
                    className="border p-2 rounded w-full md:w-auto"
                />
                <label className="flex items-center">
                    <input
                        type="checkbox"
                        name="noInterpreter"
                        checked={filters.noInterpreter || false}
                        onChange={handleFilterChange}
                        className="mr-2"
                    />
                    Keine Dolmetscher zugewiesen
                </label>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {filteredJobs.map((jobs) => (
                    <div key={jobs.id} className="border rounded-lg shadow-lg bg-white flex flex-col h-full">
                        <div className="p-6 flex-grow">
                            <h3 className="text-xl font-bold mb-4 text-center">Erstellt von [{jobs.auftraggeber}] - ID [{jobs.userId}]</h3>
                            <p><strong>Termin am: </strong>{formatDate(new Date(jobs.date))} Uhr</p>
                            <p><strong>Dauer: </strong>{jobs.duration} Minuten</p>
                            <p><strong>Sprache:</strong> {jobs.language}</p>
                            <p><strong>Stadt: </strong>{jobs.locationDTO.city}</p>
                            <p><strong>Info: </strong> {jobs.additionalInfo}</p>
                            <p><strong>Status:</strong> {jobs.interpreterId === null ? "Offen" : "Akzeptiert"}</p>
                            <p><strong>Dolmetscher: </strong> {jobs.assignedInterpreter === null ? "Leer" : jobs.assignedInterpreter}</p>
                            <p><strong>Einsatzgebiet: </strong> {jobs.industry}</p>
                        </div>
                        <button
                            onClick={() => navigate(`/dashboard-admin/auftraege/${jobs.id}`)}
                            className="w-full bg-duoColours-2 text-duoColours-1 font-bold py-2 px-4 rounded-b-lg"
                        >
                            Job einsehen
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );

}