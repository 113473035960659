import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {createAuthHeader, fetchUrl} from "../../utils/util";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";

/**
 * Zeigt zu einem Nutzer seine ganzen Informationen an.
 * Man kann hier auch seine Jobs und Feedbacks sehen.
 */
export function NutzerIDAdmin() {
    const {userID} = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [jobsVisible, setJobsVisible] = useState(false);
    const [feedbackVisible, setFeedbackVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchUser() {
            try {
                const url = `${fetchUrl}/api/admin/user/${userID}`;
                const response = await fetch(url, {
                    headers: {
                        ...createAuthHeader(),
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setUser(data);
                } else {
                    throw new Error('Fehler beim Laden des Nutzers: ' + response.status);
                }
            } catch (error) {
                console.error(error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }

        fetchUser();
    }, [userID]);


    const toggleJobsVisibility = () => {
        setJobsVisible(!jobsVisible);
    };

    const toggleFeedbackVisibility = () => {
        setFeedbackVisible(!feedbackVisible);
    };

    if (loading) {

        return <div>Laden...</div>;
    }
    if (error) {

        return <div>Fehler: {error}</div>;
    }
    if (!user) {

        return <div>Kein Nutzer gefunden</div>;
    }
    const sortedJobs = user.jobs ? [...user.jobs].sort((a, b) => new Date(a.date) - new Date(b.date)) : [];

    return (
        <div className="container mx-auto mt-8 px-4">
            <h1 className="text-2xl font-bold mb-8 text-center">Nutzer Details</h1>
            <div className="border p-6 rounded-lg shadow-lg bg-white">
                <h3 className="text-xl font-bold mb-4 text-center">{user.role} - {user.id}</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <p><strong>Vorname:</strong> {user.firstName}</p>
                    <p><strong>Nachname:</strong> {user.lastName}</p>
                    <p><strong>Mail:</strong> {user.email}</p>
                    <p><strong>Telefon:</strong> {user.phone}</p>
                    <p><strong>Geschlecht:</strong> {user.gender}</p>
                    <p><strong>Verifiziert:</strong> {user.enabled.toString().toUpperCase()}</p>
                    <p><strong>Mail Benachrichtigung:</strong> {user.mailNotification.toString()}</p>
                    {user.role === 'CLIENT' && (
                        <>
                            <p><strong>Firma:</strong> {user.company}</p>
                            <p><strong>Ansprechpartner:</strong> {user.contactPerson}</p>
                        </>
                    )}
                    {user.role === 'INTERPRETER' && (
                        <>
                            <p><strong>Sprachen:</strong> {user.languages.join(', ')}</p>
                        </>
                    )}
                </div>

                <div className="mt-8">
                    <h4 className="font-bold flex items-center cursor-pointer text-lg mb-4"
                        onClick={toggleJobsVisibility}>
                        Jobs
                        {jobsVisible ? <FaChevronUp className="ml-2"/> : <FaChevronDown className="ml-2"/>}
                    </h4>
                    {jobsVisible && (
                        sortedJobs.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {sortedJobs.map((job) => (
                                    <div key={job.id}
                                         className="border rounded-lg shadow-sm bg-gray-100 flex flex-col h-full">
                                        <div className="p-4 flex-grow">
                                            <p><strong>Job ID:</strong> {job.id}</p>
                                            <p><strong>Sprache:</strong> {job.language}</p>
                                            <p><strong>Stadt:</strong> {job.locationDTO.city}</p>
                                            <p><strong>Adresse:</strong> {job.locationDTO.address}</p>
                                            <p><strong>Raum:</strong> {job.locationDTO.room}</p>
                                            <p><strong>Datum:</strong> {new Date(job.date).toLocaleString()}</p>
                                            <p><strong>Dauer:</strong> {job.duration} Minuten</p>
                                            <p><strong>Industrie:</strong> {job.industry}</p>
                                            <p><strong>Zusätzliche Informationen:</strong> {job.additionalInfo}</p>
                                        </div>
                                        <div className="mt-auto">
                                            <button
                                                onClick={() => navigate(`/dashboard-admin/auftraege/${job.id}`)}
                                                className="w-full bg-duoColours-2 text-duoColours-1 font-bold py-2 rounded-b-lg"
                                            >
                                                Job einsehen
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>Keine Jobs vorhanden</p>
                        )
                    )}
                </div>
                <div className="mt-8">
                    <h4 className="font-bold flex items-center cursor-pointer text-lg mb-4"
                        onClick={toggleFeedbackVisibility}>
                        Feedback
                        {feedbackVisible ? <FaChevronUp className="ml-2"/> : <FaChevronDown className="ml-2"/>}
                    </h4>
                    {feedbackVisible && (
                        user.feedbacks && user.feedbacks.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {user.feedbacks.map((feedback) => (
                                    <div key={feedback.id}
                                         className="border rounded-lg shadow-sm bg-gray-100 flex flex-col h-full">
                                        <div className="p-4 flex-grow">
                                            <p><strong>Feedback: </strong> {feedback.feedback}</p>
                                            <p><strong>Erstellt
                                                am: </strong> {new Date(feedback.createdAt).toLocaleString()}</p>
                                            <p><strong>Gelöst: </strong> {feedback.resolved.toString().toUpperCase()}
                                            </p>
                                            <p><strong>Issue Link: </strong> <a href={feedback.issueTicketLink}> {feedback.issueTicketLink}</a></p>
                                        </div>
                                        <div className="mt-auto">
                                            <button
                                                onClick={() => navigate(`/dashboard-admin/feedbacks`)}
                                                className="w-full bg-duoColours-2 text-duoColours-1 font-bold py-2 rounded-b-lg"
                                            >
                                                Alle Feedbacks
                                            </button>
                                        </div>
                                    </div>

                                ))}
                            </div>
                        ) : (
                            <p>Keine Feedbacks vorhanden</p>
                        )
                    )}
                </div>
                <div>
                    <h4 className="font-bold mt-8 text-lg">Rechnungen</h4>
                    {user.invoices && user.invoices.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {user.invoices.map((invoice) => (
                                <div key={invoice.id} className="border p-4 mt-4 rounded-lg shadow-sm bg-gray-100">
                                    <p><strong>Rechnung ID:</strong> {invoice.id}</p>
                                    <p><strong>Betrag:</strong> {invoice.amount}</p>
                                    {/* Füge hier weitere Rechnungs-Attribute hinzu */}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>Keine Rechnungen vorhanden</p>
                    )}
                </div>
                <div className="flex justify-center mt-8">
                    <button onClick={() => navigate(-1)}
                            className="bg-duoColours-2 text-duoColours-1 font-bold py-2 px-6 rounded">
                        Zurück
                    </button>
                </div>
            </div>
        </div>
    );

}
