import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {formOptions,createAuthHeader, fetchUrl, formatDate} from "../../utils/util";
import {de} from "date-fns/locale";
import {startOfTomorrow} from "date-fns";

function JobPreview({ job }) {
    return (
        <div className="w-full md:w-1/2 p-4">
            <div className="border-2 border-duoColours-2 p-4 rounded-lg shadow-sm">
                <h3 className="text-lg font-semibold">Auftragsvorschau</h3>
                <div className="mt-4 space-y-2 break-words">
                    {job.language && <p><strong>Sprache:</strong> {job.language}</p>}
                    {job.locationDTO.city && <p><strong>Stadt:</strong> {job.locationDTO.city}</p>}
                    {job.locationDTO.address && <p><strong>Adresse:</strong> {job.locationDTO.address}</p>}
                    {job.locationDTO.room && <p><strong>Raum:</strong> {job.locationDTO.room}</p>}
                    {job.date && (
                        <p>
                            <strong>Datum:</strong> {formatDate(new Date(job.date))}
                        </p>
                    )}
                    {job.duration && <p><strong>Dauer:</strong> {job.duration} Minuten</p>}
                    {job.industry && <p><strong>Branche:</strong> {job.industry}</p>}
                    {job.additionalInfo && <p><strong>Zusätzliche Infos:</strong> {job.additionalInfo}</p>}
                    {job.interpreter ? (
                        <p><strong>Dolmetscher:</strong> {job.interpreter.firstName} {job.interpreter.lastName}</p>
                    ) : (
                        <p><strong>Dolmetscher:</strong> Nicht ausgewählt</p>
                    )}

                </div>
            </div>
        </div>
    );
}


export default function AuftraegeErstellenClient() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [jobError, setJobError] = useState('');
    const [confirmChecked, setConfirmChecked] = useState(false);
    const [interpreters, setInterpreters] = useState([]);
    const [selectSpecificInterpreter, setSelectSpecificInterpreter] = useState(false);

    const [job, setJob] = useState({
        language: '',
        locationDTO: {city: '', address: '', room: ''},
        date: '',
        duration: '',
        industry: '',
        createdAt: new Date(),
        interpreterId: null, // Setzen Sie auf null, da beim Erstellen noch kein Dolmetscher zugewiesen ist
        suggestedInterpreterId: -1,
        additionalInfo: '',
        userId: localStorage.getItem('userId')
    });

    useEffect(() => {
        if (job.language) {
            const fetchInterpreters = async () => {
                try {
                    const response = await fetch(`${fetchUrl}/api/users/interpreters?language=${job.language}&userId=${job.userId}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            ...createAuthHeader()
                        }
                    });
                    const data = await response.json();
                    setInterpreters(data);
                } catch (error) {
                    console.error('Fehler beim Abrufen der Dolmetscher:', error);
                }
            };

            fetchInterpreters();
        }
    }, [job.language, job.userId]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!confirmChecked) {
            setJobError('Bitte bestätigen Sie die Richtigkeit der Daten, bevor Sie fortfahren.');
            setIsLoading(false);
            return;
        }

        try {
            const response = await fetch(fetchUrl + '/api/jobs/client/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...createAuthHeader()
                },
                body: JSON.stringify(job)
            });

            if (response.ok) {
                navigate('/dashboard-client/auftraege-anzeigen');
            } else {
                console.error('Fehler bei der Auftragserstellung ', response.status);
                setJobError('Auftragserstellung fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.');
            }
        } catch (error) {
            console.error('Fehler bei der Auftragstellung', error);
            setJobError('Auftragserstellung fehlgeschlagen. Bitte versuchen Sie es später noch einmal.');
        }
        setIsLoading(false);
    };

    return (
        <div className="container mx-auto mt-8 px-4">
            <h1 className="text-2xl font-bold mb-4 text-center">Auftrag anlegen</h1>
            <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="w-full md:w-1/2 p-6">
                    <form onSubmit={handleSubmit} className="space-y-6" id={"jobForm"}>
                        <select
                            className="w-full p-2 border rounded"
                            value={job.language}
                            onChange={(e) => setJob({...job, language: e.target.value})}
                            required={true}
                            id={"languageInput"}
                        >
                            <option value="">Sprache auswählen</option>
                            {formOptions.languages.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <input
                            type="text"
                            value={job.locationDTO.city}
                            className="w-full p-2 border rounded"
                            onChange={(e) => setJob({
                                ...job,
                                locationDTO: {...job.locationDTO, city: e.target.value}
                            })}
                            placeholder="Stadt"
                            required={true}
                            id={"cityInput"}
                        />
                        <input
                            type="text"
                            value={job.locationDTO.address}
                            className="w-full p-2 border rounded"
                            onChange={(e) => setJob({
                                ...job,
                                locationDTO: {...job.locationDTO, address: e.target.value}
                            })}
                            placeholder="Adresse"
                            required={true}
                            id={"addressInput"}
                        />
                        <input
                            type="text"
                            value={job.locationDTO.room}
                            className="w-full p-2 border rounded"
                            onChange={(e) => setJob({
                                ...job,
                                locationDTO: {...job.locationDTO, room: e.target.value}
                            })}
                            placeholder="Raum"
                            id={"roomInput"}
                        />
                        <ReactDatePicker
                            selected={job.date ? new Date(job.date) : null}
                            onChange={(date) => setJob({...job, date})}
                            locale={de}
                            placeholderText=" Datum und Uhrzeit auswählen"
                            showTimeSelect
                            timeCaption="Uhrzeit"
                            timeIntervals={15}
                            dateFormat="Pp"
                            minDate={startOfTomorrow()}
                            className="w-full p-2 border rounded mt-1"
                            wrapperClassName="w-full"
                            calendarClassName="react-datepicker-custom"
                            id={"dateInput"}
                            required={true}
                        />
                        <select
                            className="w-full p-2 border rounded"
                            value={job.duration}
                            onChange={(e) => setJob({...job, duration: e.target.value})}
                            required={true}
                            id={"durationInput"}
                        >
                            <option value="">Termindauer in Minuten</option>
                            {formOptions.durations.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <select
                            className="w-full p-2 border rounded"
                            value={job.industry}
                            onChange={(e) => setJob({...job, industry: e.target.value})}
                            id={"industryInput"}
                            required={true}
                        >
                            <option value="">Einsatzbranche auswählen</option>
                            {formOptions.industries.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>

                        <div className="w-full p-2">
                            <label className="block pb-4">Spezifischen Dolmetscher wählen?</label>
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="selectInterpreter"
                                    value="yes"
                                    checked={selectSpecificInterpreter}
                                    onChange={() => setSelectSpecificInterpreter(true)}
                                    disabled={!job.language}
                                    className="form-checkbox"
                                    id={"selectInterpreterFormYes"}
                                />
                                <span className="ml-2">Ja</span>
                            </label>
                            <label className="inline-flex items-center ml-4">
                                <input
                                    type="radio"
                                    name="selectInterpreter"
                                    value="no"
                                    checked={!selectSpecificInterpreter}
                                    onChange={() => {
                                        setSelectSpecificInterpreter(false);
                                        setJob({
                                            ...job,
                                            suggestedInterpreterId: -1, // Reset to -1 or null
                                        });
                                    }}
                                    className="form-checkbox"
                                    id={"selectInterpreterFormNo"}
                                />
                                <span className="ml-2">Nein</span>
                            </label>
                        </div>


                        {selectSpecificInterpreter && (
                            <select
                                className="w-full p-2 border rounded"
                                value={job.suggestedInterpreterId || ''}
                                onChange={(e) => {
                                    const selectedInterpreter = interpreters.find(interpreter => interpreter.id === Number(e.target.value));
                                    setJob({
                                        ...job,
                                        suggestedInterpreterId: e.target.value || null,
                                        interpreter: selectedInterpreter || null
                                    });
                                }}
                                id={"chooseInterpreterForm"}
                            >
                                <option value="">Dolmetscher auswählen</option>
                                {interpreters.map((interpreter) => (
                                    <option key={interpreter.id} value={interpreter.id}>
                                        {interpreter.firstName} {interpreter.lastName}
                                    </option>
                                ))}
                            </select>
                        )}
                        <textarea
                            className="w-full p-2 border rounded"
                            value={job.additionalInfo}
                            onChange={(e) => setJob({...job, additionalInfo: e.target.value})}
                            placeholder="Zusätzliche Informationen"
                            maxLength={500}
                            id={"additionalInfoForm"}
                        />
                        {jobError && (
                            <p className="text-red-600 font-semibold">{jobError}</p>
                        )}
                        <div className="w-full p-2">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    className="form-checkbox"
                                    checked={confirmChecked}
                                    onChange={(e) => setConfirmChecked(e.target.checked)}
                                    id={"confirmForm"}
                                />
                                <span className="ml-2">Ich bestätige hiermit die Daten des Auftrags</span>
                            </label>
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-homeTextGradient text-white font-bold py-2 px-4 rounded"
                            disabled={isLoading}
                            id={"submitForm"}
                        >
                            {isLoading ?
                                (
                                    <div className="flex items-center justify-center text-white">
                                        <div className="spinnerButton"></div>
                                    </div>
                                ) : (
                                    'Auftrag erstellen'
                                )}
                        </button>
                    </form>{/* py-2 px-4 rounded-lg  text-lg sm:text-xl hover:no-underline*/}
                </div>
                <JobPreview job={job}/>
            </div>
        </div>
    );
}