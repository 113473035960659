import React from "react";
import { featuresImg, featuresImg1, pointIcon1 } from "../../assets";
import { featuresData } from "../../componenten/data";

const Features = () => {
  return (
    <div className="mt-20 sm:mt-32 lg:mt-40 scroll-mt-8" id="features">
      <img src={featuresImg} alt="Features Img" className="max-sm:hidden" />
      <h1 className="text-center sm:hidden text-transparent max-[420px]:text-7xl text-8xl bg-homeTextGradient bg-clip-text">
        Features
      </h1>

      <div className="flex flex-col mt-12 md:mt-32 gap-28">
        {featuresData.map((item) => (
          <div
            className="flex flex-col items-start justify-between gap-12 xl:gap-0 xl:flex-row"
            key={item.id}
          >
            <div className="w-full lg:w-[600px] max-xl:mx-auto max-xl:block">
              <h1 className="py-1 text-5xl text-transparent sm:text-6xl sm:whitespace-pre-wrap bg-homeTextGradient bg-clip-text ">
                {item.header}
              </h1>

              <img
                src={item.img}
                alt="Features Img 1"
                className="block max-sm:mx-auto mt-4 max-sm:w-[250px] relative z-[-1] sm:ml-auto sm:-mt-8 "
              />
            </div>

            <div className="flex flex-col gap-8">
              {item.textArray.map((text) => (
                <div className="flex items-start gap-3 w-full lg:w-[566.34px]">
                  {text.textImg ? (
                    <img
                      src={text.textImg}
                      alt="Point Icon 1"
                      className="mt-1"
                    />
                  ) : (
                    ""
                  )}
                  <div>
                    <h2 className="text-lg text-transparent sm:text-xl bg-homeTextGradient bg-clip-text">
                      {text.textHeader}
                    </h2>
                    <p className="text-lg sm:text-xl text-black mt-1.5">
                      {text.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
