import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {fetchUrl, formOptions} from "../utils/util";
import passwordCheck from "../Tools/PasswordChecker";
import Navbar from "../componenten/shared/navbar";


function ConfirmRegistrationModal({onClose}) {
    const navigate = useNavigate();

    return (
        <div
            className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50"
            id="confirmRegistrationModal" // Hinzugefügt
        >
            <div className="flex flex-col items-center w-full max-w-md p-6 mx-auto bg-white rounded-lg shadow-xl">
                <h2 className="mb-4 text-xl font-semibold text-center">
                    Registration erfolgreich!
                </h2>
                <p className="mb-6 text-center">
                    Ihre Registrierung war erfolgreich. Bitte verifizieren Sie Ihre
                    E-Mail-Adresse, um sich einzuloggen.
                </p>
                <button
                    onClick={() => {
                        onClose();
                        navigate("/login"); // Korrigiert: Navigiere nach dem Schließen zum Login
                    }}
                    className="w-full px-4 py-2 font-bold text-white rounded bg-homeTextGradient"
                >
                    OK
                </button>
            </div>
        </div>
    );
}

function Registration() {
    const location = useLocation();
    const [registrationError, setRegistrationError] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState('');
    const [user, setUser] = useState({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        gender: "",
        phone: "",
        userType: "client",
        companyName: "", // Spezifisch für Client
        contactPerson: "", // Spezifisch für Client
        languages: [], // Spezifisch für Interpreter
    });
    const toggleLanguage = (lang) => {
        setUser((prevUser) => {
            const langs = new Set(prevUser.languages);
            if (langs.has(lang)) {
                langs.delete(lang);
            } else {
                langs.add(lang);
            }
            return {...prevUser, languages: Array.from(langs)};
        });
    };
    // HOlt den Token aus der URL und speichert ihn im State
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tokenParam = params.get('mail');
        if (tokenParam) {
            setToken(tokenParam);
            setUser(prevUser => ({...prevUser, email: tokenParam}));
            /*fetch(`${fetchUrl}/api/users/token?token=${tokenParam}`)
                .then(response => response.json())
                .then(data => {
                    if (data.email) {
                        setUser(prevUser => ({ ...prevUser, email: data.email }));
                    }
                })
                .catch(error => {
                    console.error('Token Validierungsfehler', error);
                    setRegistrationError('Der Einladungstoken ist ungültig oder abgelaufen.');
                });*/
        }
    }, [location.search]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setRegistrationError("");

        // Überprüfe, ob Dolmetscher mindestens eine Sprache ausgewählt haben
        if (user.userType === "interpreter" && user.languages.length === 0) {
            setRegistrationError("Bitte wählen Sie mindestens eine Sprache aus.");
            return;
        }
        if (!user.gender) {
            setRegistrationError("Bitte wählen Sie Ihr Geschlecht aus.");
            return;
        }


        const passwordCheckResult = passwordCheck(user.password);
        if (passwordCheckResult !== true) {
            setRegistrationError(passwordCheckResult);
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch(fetchUrl + "/api/users/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(user),
            });
            if (response.ok) {
                setRegistrationError("");
                setTimeout(() => setShowSuccessModal(true), 500);
                setUser({
                    email: "",
                    password: "",
                    firstName: "",
                    lastName: "",
                    gender: "",
                    phone: "",
                    userType: "client",
                    companyName: "",
                    contactPerson: "",
                    languages: []
                });

            }
            if (!response.ok) {
                const errorResponse = await response.json();
                let errorMessage = "Registrierung fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.";
                if (errorResponse.error) {
                    errorMessage = errorResponse.error;
                }
                setRegistrationError(errorMessage);
            }
        } catch (error) {
            console.error("Fehler bei der Registrierung", error);
            setRegistrationError("Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.");
        }
        setIsLoading(false);
    };

    return (
        <>
            <Navbar/>
            <div className="flex items-center justify-center p-4" style={{minHeight: 'calc(100vh - 6rem)'}}>
                <div
                    className="w-full max-w-md p-6 my-20 bg-white border border-black rounded-lg shadow-lg border-opacity-20">
                    <h2 className="mb-4 text-xl font-semibold text-center">
                        Registrieren
                    </h2>
                    <p className="mb-4 text-sm text-center text-gray-600">
                        Es geht ganz schnell und einfach.
                    </p>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        {/* Gemeinsame Felder */}
                        <div className="flex space-x-2">
                            <input
                                type="text"
                                id={"firstName"}
                                value={user.firstName}
                                onChange={(e) =>
                                    setUser({...user, firstName: e.target.value})
                                }
                                placeholder="Vorname"
                                className="w-1/2 p-2 border rounded"
                                maxLength="50"
                                required
                            />
                            <input
                                type="text"
                                id={"lastName"}
                                value={user.lastName}
                                onChange={(e) => setUser({...user, lastName: e.target.value})}
                                placeholder="Nachname"
                                className="w-1/2 p-2 border rounded"
                                maxLength="50"
                                required
                            />
                        </div>

                        <input
                            type="email"
                            id={"email"}
                            value={user.email}
                            onChange={(e) => setUser({...user, email: e.target.value})}
                            placeholder="E-Mail-Adresse"
                            className="w-full p-2 border rounded"
                            maxLength="50"
                            required
                            readOnly={!!token} // E-Mail nur editierbar, wenn kein Token vorhanden ist
                        />
                        <input
                            type="tel"
                            id={"phone"}
                            value={user.phone}
                            onChange={(e) => setUser({...user, phone: e.target.value})}
                            placeholder="Telefonnummer"
                            maxLength={20}
                            className="w-full p-2 border rounded"
                        />

                        <input
                            type="password"
                            id={"password"}
                            value={user.password}
                            onChange={(e) => setUser({...user, password: e.target.value})}
                            placeholder="Neues Passwort"
                            className="w-full p-2 border rounded"
                            maxLength="50"
                            required
                        />

                        <div
                            className="flex items-center justify-around mt-2 space-x-2"
                            required
                        >
                            <label className="flex items-center w-1/2 p-2 space-x-1 border rounded">
                                <input
                                    type="radio"
                                    name="gender"
                                    value="weiblich"
                                    className="form-radio"
                                    required
                                    onChange={(e) => setUser({...user, gender: e.target.value})}
                                />
                                <span>Weiblich</span>
                            </label>
                            <label className="flex items-center w-1/2 p-2 space-x-1 border rounded">
                                <input
                                    type="radio"
                                    name="gender"
                                    value="männlich"
                                    className="form-radio "
                                    onChange={(e) => setUser({...user, gender: e.target.value})}
                                />
                                <span>Männlich</span>
                            </label>
                        </div>

                        <div className="text-center">
                            <label htmlFor="userType" className="mb-2 text-sm text-gray-600">
                                Sind Sie Dolmetscher oder Auftraggeber?
                            </label>

                            {/* Auswahl zwischen Client und Interpreter */}
                            <div>
                                <select
                                    className="w-full p-2 mt-2 border rounded"
                                    value={user.userType}
                                    id={"userType"}
                                    onChange={(e) =>
                                        setUser({...user, userType: e.target.value})
                                    }
                                >
                                    <option value="client">Auftraggeber</option>
                                    <option value="interpreter">Dolmetscher</option>
                                </select>
                            </div>
                        </div>

                        {/* Felder spezifisch für Clients */}
                        {user.userType === "client" && (
                            <>
                                <input
                                    id="companyName"
                                    className="w-full p-2 mt-2 border rounded"
                                    type="text"
                                    value={user.companyName}
                                    onChange={(e) =>
                                        setUser({...user, companyName: e.target.value})
                                    }
                                    placeholder="Firmenname / Auftraggeber"
                                    maxLength="50"
                                    required
                                />
                                {/*<input
                                id="contactPerson"
                                className="w-full p-2 mt-2 border rounded"
                                type="text"
                                value={user.contactPerson}
                                onChange={e => setUser({...user, contactPerson: e.target.value})}
                                placeholder="Kontaktperson"
                            />*/}
                            </>
                        )}

                        {/* Felder spezifisch für Dolmetscher */}
                        {user.userType === "interpreter" && (
                            <div className="text-center">
                                <label
                                    htmlFor="languages"
                                    className="mb-2 text-sm text-gray-600"
                                >
                                    Wählen Sie ihre Sprachen
                                </label>
                                <div className="flex flex-wrap" id="languages">
                                    {formOptions.languages.map((language) => (
                                        <button
                                            type="button"
                                            key={language.value}
                                            onClick={() => toggleLanguage(language.value)}
                                            className={`m-1 ${
                                                user.languages.includes(language.value)
                                                    ? "bg-blue-600 text-white"
                                                    : "bg-gray-200 text-black"
                                            } rounded px-3 py-1`}
                                        >
                                            {language.label}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div>
                            {registrationError && (
                                <p className="font-semibold text-red-600">
                                    {registrationError}
                                </p>
                            )}
                        </div>
                        <button
                            type="submit"
                            className="w-full px-4 py-2 font-bold text-white rounded bg-homeTextGradient"
                            disabled={isLoading}
                        >
                            {isLoading ?
                                (
                                    <div className="flex items-center justify-center text-white">
                                        <div className="spinnerButton"></div>
                                    </div>
                                ) : ('Registrieren')}
                        </button>
                    </form>

                    <div
                        className="hidden bg-center bg-cover md:block md:w-1/2"
                        style={{backgroundImage: 'url("/loginBild.webp")'}}
                    ></div>
                </div>
                <div className="registration-container">
                    {/* Ihre Formularelemente und Logik */}
                    {showSuccessModal && (
                        <ConfirmRegistrationModal
                            onClose={() => setShowSuccessModal(false)}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default Registration;
