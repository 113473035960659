import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {fetchUrl} from "../../utils/util";
import {AnimatePresence, motion} from "framer-motion";
import {LinksOverlay} from "../../componenten/shared/drawer";
import {loginBild, logo} from "../../assets";
import Navbar from "../../componenten/shared/navbar";

function CertificationPage() {
    let [keyParams] = useSearchParams();
    let [token, setToken] = useState("");
    let [loaded, setLoaded] = useState(false);
    let [error, setError] = useState(false);
    const [countdown, setCountdown] = useState(5); // Zähler für den Countdown

    useEffect(() => {
        let tempKey = keyParams.get("key");
        if (tempKey !== "") {
            // cut ?key= from key
            tempKey = tempKey.replace("key=", "");
            // change spacebar to +
            tempKey = tempKey.replaceAll(" ", "+");
            setToken(tempKey);
            console.log("Token:" + tempKey);
        }
    }, [keyParams]);

    useEffect(() => {
            if (token !== "") {
                console.log("Send:" + token);
                sendToken().then(() => console.log("Key sent"));
            }
        }
        , [token]);

    useEffect(() => {
        if (loaded && !error) {
            const interval = setInterval(() => {
                setCountdown((prevCount) => prevCount - 1);
            }, 1000);

            if (countdown === 0) {
                window.location.href = "/login"; // Weiterleitung zur Login-Seite
            }

            return () => clearInterval(interval); // Säubere den Timer, wenn Komponente unmontiert wird
        }
    }, [loaded, error, countdown]);

    //create async function to send key to backend
    async function sendToken() {
        await fetch(fetchUrl + '/api/users/certify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: token
        }).then(r => {
            if (r.ok) {
                setError(false);
                setLoaded(true);
            } else {
                setError(true);
                setLoaded(true);
            }
        })
            .catch(e => {
                console.log(e);
                setError(true);
                setLoaded(true);
            });
    }

    return (
        <div className="">
            <Navbar />
            <div className="flex items-center justify-center h-screen p-4">
                <div
                    className="flex w-full max-w-5xl bg-white border border-black rounded-lg shadow-lg border-opacity-20">
                    <div className="w-full p-10">
                        <div className="text-center text-duoColours-2 text-2xl">
                            {error && loaded ? (
                                <p>Es ist ein Fehler aufgetreten, bitte versuche es später erneut.</p>
                            ) : loaded && !error ? (
                                <div>
                                    <p>Danke für deine Registrierung, dein Account wurde verifiziert.</p>
                                    <p>Du wirst in <b>{countdown}</b> Sekunden weitergeleitet.</p>
                                </div>
                            ) : (
                                <div>
                                    <p>Verifiziere deinen Account...</p>
                                    <div
                                        className="flex items-center justify-center w-full"
                                        style={{height: "200px"}}
                                    >
                                        <div className="spinner"></div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default CertificationPage;
