/**
 * Überprüft ein Passwort auf die folgenden Kriterien:
 * - Mindestens 8 Zeichen lang
 * - Mindestens ein Großbuchstabe
 * - Mindestens ein Kleinbuchstabe
 * - Mindestens eine Zahl
 * - Mindestens ein Sonderzeichen
 * @param password - Das zu überprüfende Passwort
 * @returns {boolean|string} - Gibt true zurück, wenn das Passwort den Kriterien entspricht, ansonsten eine Fehlermeldung
 */
function passwordCheck (password) {
    const upperCase = new RegExp('[A-Z]');
    const lowerCase = new RegExp('[a-z]');
    const numbers = new RegExp('[0-9]');
    const specialChars = new RegExp('[!@#$%^&*(),.?":{}|<>]');
    if (password.length < 8) {
        return 'Das Passwort muss mindestens 8 Zeichen lang sein.';
    }
    if (!upperCase.test(password)) {
        return 'Das Passwort muss mindestens einen Großbuchstaben enthalten.';
    }
    if (!lowerCase.test(password)) {
        return 'Das Passwort muss mindestens einen Kleinbuchstaben enthalten.';
    }
    if (!numbers.test(password)) {
        return 'Das Passwort muss mindestens eine Zahl enthalten.';
    }
    if (!specialChars.test(password)) {
        return 'Das Passwort muss mindestens ein Sonderzeichen enthalten.';
    }
    return true;
}

export default passwordCheck;