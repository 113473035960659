import React, { useEffect, useState } from "react";
import {createAuthHeader, fetchUrl, formatDate} from "../../utils/util";

export default function MeineAuftraegeInterpreter() {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchJobs() {
            const interpreterId = localStorage.getItem('userId');
            if (!interpreterId) {
                console.error('Benutzer-ID nicht gefunden.');
                setError('Benutzer-ID nicht gefunden.');
                setLoading(false);
                return;
            }
            try {

                const response = await fetch(`${fetchUrl}/api/jobs/interpreter/jobs?userId=${interpreterId}&type=accepted`, {
                    headers: {
                        'Content-Type': 'application/json',
                        ...createAuthHeader(),
                    },
                });

                if (!response.ok) {
                    setError('Fehler beim Laden der verfügbaren Jobs: ' + response.status);
                    return;
                }
                const data = await response.json();
                setJobs(data);
            } catch (error) {
                console.error(error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
        fetchJobs();
    }, []);

    if (loading) {
        return <div className="text-center mt-10">Laden...</div>;
    }

    if (error) {
        return <div className="text-center mt-10 text-red-500">Fehler: {error}</div>;
    }

    return (
        <div className="container mx-auto mt-10 px-4 ">
            <h1 className="text-2xl font-bold mb-8 text-center">Meine Aufträge</h1>
            {jobs.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {jobs.map((job) => (
                        <div key={job.id} className="border rounded-lg shadow-lg bg-white p-6 flex flex-col h-full">
                            <div className="flex-grow">
                                <h3 className="text-lg font-bold mb-4 text-center">{job.language} - {job.industry}</h3>
                                <p><strong>Stadt:</strong> {job.locationDTO?.city || 'Nicht verfügbar'}</p>
                                <p><strong>Adresse:</strong> {job.locationDTO?.address || 'Nicht verfügbar'}</p>
                                <p><strong>Raum:</strong> {job.locationDTO?.room || 'Nicht verfügbar'}</p>
                                <p>
                                    <strong>Datum:</strong> {formatDate(new Date(job.date))} Uhr
                                </p>
                                <p><strong>Dauer:</strong> {job.duration || 'Nicht verfügbar'} Minuten</p>
                                {job.additionalInfo !== '' && (
                                    <p><strong>Infos:</strong> {job.additionalInfo}</p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="text-center mt-10">Sie haben keine akzeptierten Aufträge in der Zukunft.</div>
            )}
        </div>
    );
}
