import React from "react";
import { comaIcon, profileIcon, swiperCardBg } from "../../assets";
import { FaStar } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const WhatClientSays = () => {
  const breakpoints = {
    100: {
      slidesPerView: 1,
    },
    576: {
      slidesPerView: 1,
    },

    768: {
      slidesPerView: 1,
    },
    1021: {
      slidesPerView: 1,
    },
    1200: {
      slidesPerView: 1.5,
    },
    1300: {
      slidesPerView: 1.8,
    },
    1500: {
      slidesPerView: 2.1,
    },
  };

  const clientsCardData = [
    {
      id: 1,
      header: "Amanda S",
      text: " The platform has revolutionized how we communicate with our international clients. It’s fast, easy, and the translations are always spot on!",
    },
    {
      id: 2,
      header: "Amanda S",
      text: " The platform has revolutionized how we communicate with our international clients. It’s fast, easy, and the translations are always spot on!",
    },
    {
      id: 3,
      header: "Amanda S",
      text: " The platform has revolutionized how we communicate with our international clients. It’s fast, easy, and the translations are always spot on!",
    },
    {
      id: 4,
      header: "Amanda S",
      text: " The platform has revolutionized how we communicate with our international clients. It’s fast, easy, and the translations are always spot on!",
    },
  ];

  return (
    <div className="mt-20 sm:mt-32 lg:mt-40">
      <h2 className="text-4xl text-center text-black sm:text-6xl font-pp">
        What Our Clients and Translators Are Saying
      </h2>

      <div className="grid grid-cols-1 gap-4 mt-12 lg:grid-cols-2 justify-items-center">
        {clientsCardData.map((item) => (
          <div className="relative isolate w-full  bg-[#F7F9FF] py-7 rounded-xl ">
            <img
              src={comaIcon}
              alt="Coma Icon"
              className="absolute top-8 right-4"
            />

            <div className="flex flex-col gap-4 px-8 ">
              <h1 className="text-xl font-semibold text-black font-pp ">
                {item.header}
              </h1>

              <div className="flex items-center gap-2">
                <FaStar className="text-[#FFBB00] text-xl" />
                <FaStar className="text-[#FFBB00] text-xl" />
                <FaStar className="text-[#FFBB00] text-xl" />
                <FaStar className="text-[#FFBB00] text-xl" />
                <FaStar className="text-[#FFBB00] text-xl" />
              </div>

              <p className="text-[#999999] font-medium">{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatClientSays;
