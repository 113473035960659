import React, { useRef } from "react";
import Navbar from "../componenten/shared/navbar";
import { VscArrowLeft, VscArrowRight } from "react-icons/vsc";
import { useData } from "../componenten/data";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { FaStar } from "react-icons/fa";
import { comaIcon, profileIcon, swiperCardBg } from "../assets";

const Clients = () => {
  const swiperRef = useRef();

  const swiperData = [
    {
      id: 1,
      header: "Vertrauensvoller Partner",
      text: "Wir dolmetschen seit über 25 Jahren in Deutschland und bieten eine vertrauensvolle & gerechte Partnerschaft",
    },
    {
      id: 2,
      header: "Einfache Auftragsverwaltung",
      text: "Einfaches Erstellen, Bearbeiten und Verwalten von Übersetzungsaufträgen in einer benutzerfreundlichen Plattform, die den gesamten Prozess vereinfacht.",
    },
    {
      id: 3,
      header: "Ihre Meinung",
      text: " Wir wertschätzen Ihre Meinung zu unseren Dolmetschern aber auch unseren Service",
    },
  ];

  const clientsCardData = [
    {
      id: 1,
      header: "Amanda S",
      text: " The platform has revolutionized how we communicate with our international clients. It’s fast, easy, and the translations are always spot on!",
    },
    {
      id: 2,
      header: "Amanda S",
      text: " The platform has revolutionized how we communicate with our international clients. It’s fast, easy, and the translations are always spot on!",
    },
    {
      id: 3,
      header: "Amanda S",
      text: " The platform has revolutionized how we communicate with our international clients. It’s fast, easy, and the translations are always spot on!",
    },
    {
      id: 4,
      header: "Amanda S",
      text: " The platform has revolutionized how we communicate with our international clients. It’s fast, easy, and the translations are always spot on!",
    },
  ];

  return (
    <div className="">
      <Navbar />
      <div className="container min-w-0">
        {/* header  */}
        <h1 className="text-[#E6F3FF] text-end font-pp max-[350px]:text-4xl max-sm:my-4 text-5xl sm:text-8xl  lg:text-[150px] xl:text-[180px]">
          Auftraggeber
        </h1>
        <div className="bg-[#427EFE] bg-opacity-10 rounded-lg w-[164px] h-[45px] sm:h-[51px] flex items-center justify-center">
          <p className="text-xl text-transparent bg-textGradient bg-clip-text">
            Auftraggeber
          </p>
        </div>
        <h2 className="mt-4 text-4xl leading-tight text-black sm:text-6xl lg:text-7xl">
          Effiziente{" "}
          <span className="text-transparent bg-textGradient bg-clip-text">
            {" "}
            Dolmetschdienste
          </span>{" "}
          <br className="max-xl:hidden" /> für Ihre Termine
        </h2>
        <div className="flex flex-col justify-between gap-4 mt-5 xl:items-center xl:flex-row xl:gap-12">
          <p className="text-[#999999] text-lg sm:text-xl lg:text-2xl ">
            Buchen Sie qualifizierte Dolmetscher für medizinische, juristische
            oder behördliche Termine und sorgen Sie für eine reibungslose
            Kommunikation.
          </p>

          <a href="/register">
            <button className="bg-textGradient rounded-lg w-[200px] xl:w-[320.96px] h-[50px] sm:h-[60px] lg:h-[70px] flex items-center gap-2 text-lg 2xl:text-xl text-white justify-center">
              Jetzt registrieren
              <VscArrowRight className="text-2xl sm:text-3xl " />
            </button>
          </a>
        </div>
        {/* header  */}

        {/* how to use  */}
        <div className="mt-20 sm:mt-32 lg:mt-40">
          <h2 className="text-5xl text-center text-black sm:text-6xl font-pp">
            Wie funktioniert es?
          </h2>

          <div className="flex flex-col mt-0 md:mt-14">
            {useData.map((item, index) => (
              <div
                className={`flex xl:items-center max-xl:flex-col  justify-between ${
                  index % 2 ? "flex-row-reverse" : ""
                }`}
                key={index}
              >
                <div>
                  <img
                    src={item.img}
                    alt="useImg1"
                    className="max-xl:mx-auto max-md:w-[500px]"
                  />
                </div>

                <div className="w-full md:w-[680px] border-l-4 ps-4 h-fit border-[#0182FF]">
                  <p className="text-[#E6F2FF] font-pp text-9xl sm:text-[150px] md:text-[200px] font-semibold ">
                    0{index + 1}
                  </p>
                  {item.id === 6 && (
                    <div className="bg-[#427EFE] bg-opacity-10 mb-4 rounded-lg w-[164px] h-[40px] flex items-center justify-center">
                      <p className="text-lg text-transparent bg-textGradient bg-clip-text">
                        Coming soon!
                      </p>
                    </div>
                  )}
                  <h1 className="max-[400px]:text-2xl text-3xl leading-tight text-black sm:text-4xl md:text-5xl font-pp">
                    {item.header}
                  </h1>

                  <p className=" sm:text-lg md:text-xl text-[#999999] mt-1 ">
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* how to use  */}

        {/* why choose us */}
        <div className="flex flex-col min-w-0 gap-8 mt-20 xl:items-center xl:flex-row">
          <div className="flex flex-col gap-4   xl:w-[500px] 2xl:w-[707px]">
            <h1 className="max-[400px]:text-2xl text-3xl md:text-5xl text-black font-pp ">
              Warum sollten Sie mit uns zusammenarbeiten?
            </h1>
            <p className="text-xl text-[#999999]">
              Entdecken Sie, wie unsere Plattform Ihre wertvolle Zeit & Nerven
              spart
            </p>
            <button className="flex items-center gap-2 text-xl w-fit">
              Unsere Vorteile ansehen
              <VscArrowRight className="text-2xl " />
            </button>
          </div>

          <div className="bg-[#F0F7FE] rounded-3xl p-4 sm:p-6 !min-w-0 flex-1">
            <Swiper
              modules={[Navigation]}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              slidesPerView={1.5}
              spaceBetween={30}
            >
              {swiperData.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="pt-2 bg-textGradient rounded-3xl ">
                    <div className="flex flex-col gap-5 p-4 bg-white sm:p-6 rounded-3xl">
                      {/* <p className="max-[400px]:text-3xl text-4xl md:text-5xl">
                        📝
                      </p> */}
                      <h1 className="text-xl sm:text-3xl ">{item.header}</h1>
                      <p className="text-[#999999] min-h-[100px] max-sm:text-sm  ">
                        {item.text}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="flex items-center gap-4 mt-8">
              <div
                className="bg-[#999999] hover:bg-textGradient rounded-lg group size-14 p-[1px] cursor-pointer "
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <div className="bg-[#F0F7FE] rounded-lg w-full h-full flex items-center justify-center">
                  <VscArrowLeft className="text-2xl text-[#999999] group-hover:text-[#017BFF]" />
                </div>
              </div>
              <div
                className="bg-[#999999] rounded-lg size-14 hover:bg-textGradient group p-[1px] cursor-pointer "
                onClick={() => swiperRef.current?.slideNext()}
              >
                <div className="bg-[#F0F7FE] rounded-lg w-full h-full flex items-center justify-center">
                  <VscArrowRight className="text-2xl text-[#999999] group-hover:text-[#017BFF]  " />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* why choose us */}

        {/* what clients says */}
        {/* <div className="mt-20 select-none sm:mt-32 lg:mt-40">
          <h2 className="text-4xl text-center text-black lg:text-6xl font-pp">
            What Our Clients Are Saying
          </h2>

          <div className="grid grid-cols-1 gap-4 mt-12 lg:grid-cols-2 justify-items-center">
            {clientsCardData.map((item) => (
              <div className="relative isolate w-full  bg-[#F7F9FF] py-7 rounded-xl ">
                <img
                  src={comaIcon}
                  alt="Coma Icon"
                  className="absolute top-8 right-4"
                />

                <div className="flex flex-col gap-4 px-8 ">
                  <h1 className="text-xl font-semibold text-black font-pp ">
                    {item.header}
                  </h1>

                  <div className="flex items-center gap-2">
                    <FaStar className="text-[#FFBB00] text-xl" />
                    <FaStar className="text-[#FFBB00] text-xl" />
                    <FaStar className="text-[#FFBB00] text-xl" />
                    <FaStar className="text-[#FFBB00] text-xl" />
                    <FaStar className="text-[#FFBB00] text-xl" />
                  </div>

                  <p className="text-[#999999] font-medium">{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div> */}
        {/* what clients says */}
      </div>
    </div>
  );
};

export default Clients;
