import React, {useEffect, useState} from "react";
import {formOptions, createAuthHeader, fetchUrl, useFilter, formatDate} from "../../utils/util";
import ReactDatePicker from "react-datepicker";
import {de} from "date-fns/locale";

function EditJobModal({job, onClose, onSave}) {
    const [editedJob, setEditedJob] = useState({...job});
    const [isChanged, setIsChanged] = useState(false);
    const [changedFields, setChangedFields] = useState({});

    useEffect(() => {
        const hasChanges = Object.keys(job).some(key => {
            if (job[key] instanceof Object && editedJob[key] instanceof Object) {
                return JSON.stringify(job[key]) !== JSON.stringify(editedJob[key]);
            }
            return job[key] !== editedJob[key];
        });
        setIsChanged(hasChanges);
    }, [editedJob, job]);


    const handleSave = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${fetchUrl}/api/jobs/client/${editedJob.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    ...createAuthHeader(),
                },
                body: JSON.stringify(editedJob),
            });
            if (!response.ok) {
                throw new Error('Fehler beim Aktualisieren des Jobs');
            }
            const updatedJob = await response.json();
            onSave(updatedJob);
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } catch (error) {
            console.error('Fehler beim Speichern des Jobs', error);
        }
    };

    const handleChange = (name, value) => {
        setChangedFields(prev => {
            const newFields = {...prev, [name]: value !== job[name]};
            if (name.startsWith('locationDTO.')) {
                const locationKey = name.split('.')[1];
                const newLocation = {
                    ...editedJob.locationDTO,
                    [locationKey]: value
                };
                return {
                    ...newFields,
                    [`locationDTO.${locationKey}`]: JSON.stringify(newLocation) !== JSON.stringify(job.locationDTO)
                };
            }
            return newFields;
        });

        if (name.startsWith('locationDTO.')) {
            const locationKey = name.split('.')[1];
            setEditedJob(prev => ({
                ...prev,
                locationDTO: {...prev.locationDTO, [locationKey]: value}
            }));
        } else {
            setEditedJob(prev => ({...prev, [name]: value}));
        }
    };

    const getFieldClass = (fieldName) => {
        return changedFields[fieldName] ? 'bg-green-100' : '';
    };

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50 overflow-y-auto">
            <div
                className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg mx-4 md:mx-0 my-4 overflow-y-auto"
                style={{maxHeight: '90vh'}}
                id="editJobModal"
            >

                <h2 className="text-2xl font-bold mb-6 text-center">Job bearbeiten</h2>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (isChanged) {
                            handleSave(e);
                        }
                    }} className="space-y-3">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Sprache</label>
                        <select
                            className={`w-full p-2 border rounded mt-1 ${getFieldClass('language')}`}
                            value={editedJob.language}
                            onChange={(e) => handleChange('language', e.target.value)}
                            id="languageInput"
                        >
                            <option value="">Sprache auswählen</option>
                            {formOptions.languages.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Stadt</label>
                        <input
                            type="text"
                            className={`w-full p-2 border rounded mt-1 ${getFieldClass('locationDTO.city')}`}
                            value={editedJob.locationDTO.city}
                            onChange={(e) => handleChange('locationDTO.city', e.target.value)}
                            placeholder="Stadt"
                            id="cityInput"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Adresse</label>
                        <input
                            type="text"
                            className={`w-full p-2 border rounded mt-1 ${getFieldClass('locationDTO.address')}`}
                            value={editedJob.locationDTO.address}
                            onChange={(e) => handleChange('locationDTO.address', e.target.value)}
                            placeholder="Adresse"
                            id="addressInput"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Raum</label>
                        <input
                            type="text"
                            className={`w-full p-2 border rounded mt-1 ${getFieldClass('locationDTO.room')}`}
                            value={editedJob.locationDTO.room}
                            onChange={(e) => handleChange('locationDTO.room', e.target.value)}
                            placeholder="Raum"
                            id="roomInput"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Datum</label>
                        <ReactDatePicker
                            selected={job.date ? new Date(job.date) : null}
                            onChange={(date) => handleChange('date', date)}
                            locale={de}
                            placeholderText=" Datum und Uhrzeit auswählen"
                            showTimeSelect
                            timeCaption="Uhrzeit"
                            timeIntervals={15}
                            dateFormat="Pp"
                            minDate={new Date()}
                            className={`w-full p-2 border rounded mt-1 ${getFieldClass('date')}`}
                            wrapperClassName="w-full"
                            calendarClassName="react-datepicker-custom"
                            id="datePicker"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Dauer</label>
                        <select
                            className={`w-full p-2 border rounded mt-1 ${getFieldClass('duration')}`}
                            value={editedJob.duration}
                            onChange={(e) => handleChange('duration', e.target.value)}
                            id="durationInput"
                        >
                            <option value="">Dauer auswählen</option>
                            {formOptions.durations.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Einsatzbranche</label>
                        <select
                            className={`w-full p-2 border rounded mt-1 ${getFieldClass('industry')}`}
                            value={editedJob.industry}
                            onChange={(e) => handleChange('industry', e.target.value)}
                            id="industryInput"
                        >
                            <option value="">Einsatzbranche auswählen</option>
                            {formOptions.industries.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Zusätzliche Informationen</label>
                        <textarea
                            className={`w-full p-2 border rounded mt-1 ${getFieldClass('additionalInfo')}`}
                            value={editedJob.additionalInfo}
                            onChange={(e) => handleChange('additionalInfo', e.target.value)}
                            placeholder="Zusätzliche Informationen"
                            id="additionalInfoInput"
                        />
                    </div>

                    {/* Schaltflächen zum Speichern und Schließen */}
                    <div className="flex justify-center space-x-4 mt-6">
                        <button
                            type="button"
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            onClick={onClose}
                            id="closeButton"
                        >
                            Schließen
                        </button>
                        <button
                            type="submit"
                            disabled={!isChanged}
                            className={`bg-homeTextGradient text-white font-bold py-2 px-4 rounded ${!isChanged ? 'opacity-50 cursor-not-allowed' : ''}`}
                            id="saveButton"
                        >
                            Speichern
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );


}

export default function AuftraegeanzeigenClient() {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [currentJob, setCurrentJob] = useState(null);

    const filterFunctions = {
        language: (job, value) => job.language.toLowerCase().includes(value.toLowerCase()),
        date: (job, value) => new Date(job.date).toDateString() === new Date(value).toDateString(),
    };
    const {filters, handleFilterChange, filteredItems: filteredJobs} = useFilter(jobs, filterFunctions);

    const renderFilterOptions = () => {
        return (
            <div className="flex flex-col md:flex-row items-center justify-center gap-2 md:gap-4 mb-4">
                <div className="flex items-center gap-2">
                    <label htmlFor="languageFilter">Sprache:</label>
                    <select
                        id="languageFilter"
                        name="language"
                        value={filters.language || ''}
                        onChange={handleFilterChange}
                        className="border p-2 rounded"
                    >
                        <option value="">Alle</option>
                        {formOptions.languages.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                </div>
                <div className="flex items-center gap-2">
                    <label htmlFor="dateFilter">Datum:</label>
                    <input
                        type="date"
                        id="dateFilter"
                        name="date"
                        value={filters.date || ''}
                        onChange={handleFilterChange}
                        className="border p-2 rounded"
                    />
                </div>
            </div>
        );
    };


    // Anwenden der Filterfunktion auf die Aufträge


    useEffect(() => {
        async function fetchJobs() {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                console.error('Benutzer-ID nicht gefunden.');
                setError('Benutzer-ID nicht gefunden.');
                setLoading(false);
                return;
            }
            try {
                const response = await fetch(`${fetchUrl}/api/jobs/user/${userId}`, {
                    headers: {
                        ...createAuthHeader(),
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setJobs(data);
                } else {
                    throw new Error('Fehler beim Laden der Jobs: ' + response.status);
                }
            } catch (error) {
                console.error(error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }

        fetchJobs();
    }, []);

    const openEditModal = (jobToEdit) => {
        const date = new Date(jobToEdit.date);
        const formattedDate = date.toISOString().slice(0, 16);
        setCurrentJob({...jobToEdit, date: formattedDate});
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
        setCurrentJob(null);
    };

    const handleSave = (updatedJob) => {
        setJobs(jobs => jobs.map(job => job.id === updatedJob.id ? updatedJob : job));
        closeEditModal();
    };

    if (loading) {
        return <div>Laden...</div>;
    }

    if (error) {
        return <div>Fehler: {error}</div>;
    }


    const isDeleteDisabled = (jobDate) => {
        const currentTime = new Date();
        const jobTime = new Date(jobDate);
        const timeDifference = jobTime - currentTime;
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        return hoursDifference < 24;
    };

    return (
        <div className="container mx-auto mt-8 px-4">
            <h1 className="text-2xl font-bold mb-8 text-center">Meine Aufträge</h1>
            {renderFilterOptions()}
            {filteredJobs.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {filteredJobs.map((job) => (
                        <div key={job.id}
                             className="border rounded-lg shadow-lg bg-white flex flex-col h-full"
                             id={`job-${job.id}`}
                        >
                            <div className="p-6 flex-grow">
                                <h3 className="text-lg font-bold mb-4">{job.language} - {job.industry}</h3>
                                <p><strong>Stadt:</strong> {job.locationDTO?.city || 'Nicht verfügbar'}</p>
                                <p><strong>Adresse:</strong> {job.locationDTO?.address || 'Nicht verfügbar'}</p>
                                <p><strong>Raum:</strong> {job.locationDTO?.room || 'Nicht verfügbar'}</p>
                                <p><strong>Datum:</strong> {formatDate(new Date(job.date))} Uhr</p>
                                <p><strong>Dauer:</strong> {job.duration || 'Nicht verfügbar'}</p>
                                <p>
                                    <strong>Dolmetscher:</strong> {job.interpreterId === null ? 'Nicht zugewiesen' : job.assignedInterpreter}
                                </p>
                                {/*Wenn job.suggestedInterpreterId != null ist dann schreiben wir hin Gewählter Dolmetscher: */}
                                {job.additionalInfo !== '' && (
                                    <p><strong>Zusätzliche Informationen:</strong> {job.additionalInfo || 'Leer'}</p>
                                )}


                            </div>
                            <div className="mt-auto grid grid-cols-3 gap-0.5">
                                <button
                                    onClick={() => openEditModal(job)}
                                    className="col-span-2 bg-homeTextGradient text-white font-bold py-2 px-4 rounded-bl-lg"
                                    id={`editButton-${job.id}`}
                                >
                                    Bearbeiten
                                </button>
                                <button
                                    className={`col-span-1 ${isDeleteDisabled(job.date) ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500 hover:bg-red-700'} text-white font-bold py-2 px-4 rounded-br-lg`}
                                    onClick={() => {
                                        if (!isDeleteDisabled(job.date) && window.confirm(`Sind Sie sicher, dass Sie den Auftrag ${job.id} löschen wollen?`)) {
                                            fetch(fetchUrl + "/api/jobs/client/" + job.id, {
                                                method: 'DELETE',
                                                headers: {
                                                    ...createAuthHeader(),
                                                },
                                            })
                                                .then((response) => {
                                                    if (!response.ok) {
                                                        throw new Error('Fehler beim Löschen des Auftrags');
                                                    }
                                                    setJobs(jobs.filter((j) => j.id !== job.id));
                                                })
                                                .catch((error) => {
                                                    console.error('Fehler beim Löschen des Auftrags', error);
                                                });
                                        }
                                    }}
                                    id={`deleteButton-${job.id}`}
                                    disabled={isDeleteDisabled(job.date)}
                                >
                                    Löschen
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div>Keine Aufträge vorhanden.</div>
            )}
            {editModalOpen && currentJob && (
                <EditJobModal
                    job={currentJob}
                    onClose={closeEditModal}
                    onSave={handleSave}
                />
            )}
        </div>
    );


}