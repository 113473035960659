import {useState} from "react";

export const createAuthHeader = () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        return {};
    }
    return {
        'Authorization': `Bearer ${token}`
    };
};

export const formatDate = (date) => {
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    };
    return new Intl.DateTimeFormat('de-DE', options).format(date);
}

export const useFilter = (initialItems, filterFunctions) => {
    const [filters, setFilters] = useState({});

    const handleFilterChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const filteredItems = initialItems.filter(item => {
        return Object.keys(filters).every(key => {
            return filterFunctions[key] ? filterFunctions[key](item, filters[key]) : true;
        });
    });

    return {
        filters,
        setFilters,
        handleFilterChange,
        filteredItems
    };
};


export var fetchUrl = 'https://doldir.onrender.com' // 'https://doldir.onrender.com' 'http://localhost:8080'


export const formOptions = {
    languages: [
        {label: "Armenisch", value: "Armenisch"},
        {label: "Türkisch", value: "Türkisch"},
        {label: "Englisch", value: "Englisch"},
        {label: "Farsi", value: "Farsi"},
        {label: "Russisch", value: "Russisch"},
        {label: "Ukrainisch", value: "Ukrainisch"},
        {label: "Arabisch", value: "Arabisch"},
        {label: "Dari", value: "Dari"},
        {label: "Kurdisch", value: "Kurdisch"},
        {label: "Kurmandschi", value: "Kurmandschi"},
        {label: "Sorani", value: "Sorani"},
        {label: "Paschtu", value: "Paschtu"},
        {label: "Polnisch", value: "Polnisch"},
        {label: "Französisch", value: "Französisch"},
        {label: "Spanisch", value: "Spanisch"},
        {label: "Italienisch", value: "Italienisch"},
        {label: "Portugiesisch", value: "Portugiesisch"},
        {label: "Griechisch", value: "Griechisch"},
        {label: "Bulgarisch", value: "Bulgarisch"},
        {label: "Serbisch", value: "Serbisch"},
        {label: "Kroatisch", value: "Kroatisch"},
        {label: "Bosnisch", value: "Bosnisch"},
    ],
    durations: [
        {label: "30", value: 30},
        {label: "60", value: 60},
        {label: "90", value: 90},
        {label: "120", value: 120},
        {label: "150", value: 150},
        {label: "180", value: 180},
    ],
    industries: [
        {label: "Arzt", value: "Arzt"},
        {label: "Gericht", value: "Gericht"},
        {label: "Behörde", value: "Behörde"},
        {label: "Amt", value: "Amt"},
        {label: "Polizei", value: "Polizei"},
        {label: "Krankenhaus", value: "Krankenhaus"},
        {label: "Schule", value: "Schule"},
        {label: "Sonstiges", value: "Sonstiges"},
    ],
};

export const toggleMailNotification = async () => {
    const userId = localStorage.getItem('userId');
    try {
        const response = await fetch(`${fetchUrl}/api/users/mailNotification?userId=${userId}`, {
            method: 'PUT',
            headers: {
                ...createAuthHeader(),
            },
        });

        if (response.ok) {
            // referesh site
            window.location.reload();
        }
        if (!response.ok) {
            throw new Error('Fehler beim Umschalten der E-Mail-Benachrichtigungen');
        }

    } catch (error) {
        console.error("Fehler beim Umschalten der E-Mail-Benachrichtigungen");
    }
};