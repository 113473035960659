import React, {useEffect, useState} from "react";
import {createAuthHeader, fetchUrl, formatDate} from "../../utils/util";


function ConfirmAcceptModal({open, onConfirm, onCancel, job, error}) {
    const [isChecked, setIsChecked] = useState(false);
    const [jobError, setJobError] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const handleConfirmClick = () => {
        if (isChecked) {
            onConfirm();
            setJobError('');
        } else {
            setJobError('Bitte bestätigen Sie, dass Sie den Auftrag verbindlich annehmen möchten.');
        }
    };

    if (!open) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50">
            <div className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-4 md:mx-0 my-4 overflow-y-auto ${error ? 'bg-gray-100' : ''}`}>
                <h3 className="text-xl font-bold mb-4 text-center">
                    {error ? 'Auftrag vergeben' : 'Auftrag akzeptieren'}
                </h3>
                {error ? (
                    <div className="text-center mb-4">
                        <p>Dieser Auftrag wurde bereits von einem anderen Interpreter angenommen.</p>
                    </div>
                ) : (
                    <>
                        <p className="text-center mb-4">Möchten Sie den Job am <b>{formatDate(new Date(job.date))}</b> Uhr annehmen?</p>
                        <div className="my-4 flex items-center justify-center">
                            <input
                                id="confirmCheck"
                                type="checkbox"
                                checked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}
                                className="form-checkbox h-6 w-6 mr-2"
                            />
                            <label htmlFor="confirmCheck">Ich bestätige verbindlich die Annahme des Auftrags.</label>
                        </div>
                        {jobError && <p className="text-red-500 text-center">{jobError}</p>}
                    </>
                )}

                <div className="flex justify-center space-x-4 mt-4">
                    {error ? (
                        <button
                            onClick={() => {
                                onCancel();
                                window.location.reload(); // Seite aktualisieren
                            }}
                            className="bg-homeTextGradient text-white font-bold py-2 px-4 rounded"
                        >
                            OK
                        </button>
                    ) : (
                        <>
                            <button
                                onClick={onCancel}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Abbrechen
                            </button>
                            <button
                                onClick={handleConfirmClick}
                                className="bg-homeTextGradient text-white font-bold py-2 px-4 rounded"
                            >
                                {isLoading ? (
                                    <div className="flex items-center justify-center text-white">
                                        <div className="spinnerButton"></div>
                                    </div>
                                ) : (
                                    'Bestätigen'
                                )}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default function AuftraegeanzeigenInterpreter() {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [confirmAcceptOpen, setConfirmAcceptOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null); // Zustand für das ausgewählte Job
    const [acceptError, setAcceptError] = useState('');

    useEffect(() => {
        async function fetchJobs() {
            const interpreterId = localStorage.getItem('userId');
            if (!interpreterId) {
                console.error('Benutzer-ID nicht gefunden.');
                setError('Benutzer-ID nicht gefunden.');
                setLoading(false);
                return;
            }
            try {

                const response = await fetch(`${fetchUrl}/api/jobs/interpreter/jobs?userId=${interpreterId}&type=available`, {
                    headers: {
                        'Content-Type': 'application/json',
                        ...createAuthHeader(),
                    },
                });

                if (!response.ok) {
                    setError('Fehler beim Laden der verfügbaren Jobs: ' + response.status);
                    return;
                }
                const data = await response.json();
                setJobs(data);
            } catch (error) {
                console.error(error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }

        fetchJobs();
    }, []);

    const handleAcceptJob = async (job) => {
        setSelectedJob(job);
        setConfirmAcceptOpen(true);
    };

    const confirmAcceptJob = async () => {
        const interpreterId = localStorage.getItem('userId');
        const jobId = selectedJob?.id;


        if (!interpreterId || !jobId) {
            console.error('Fehler beim Identifizieren des Interpreters oder des Jobs.');
            return;
        }
        try {
            const response = await fetch(`${fetchUrl}/api/jobs/interpreter/accept?jobId=${jobId}&userId=${interpreterId}`, {
                method: 'PUT',
                headers: {
                    ...createAuthHeader(),
                },
            });

            if (!response.ok) {
                if (response.status === 400) { // Konfliktstatus (409) für "bereits akzeptiert"
                    setAcceptError('Dieser Auftrag wurde bereits von einem anderen Interpreter angenommen.');
                } else {
                    setAcceptError('Fehler beim Akzeptieren des Jobs.');
                }
                return;
            }

            setJobs(jobs.filter((job) => job.id !== jobId)); // Job aus der Liste entfernen
            setConfirmAcceptOpen(false);
        } catch (error) {
            console.error('Fehler beim Akzeptieren des Jobs', error);
            setAcceptError('Es ist ein unerwarteter Fehler aufgetreten.');
        }
    };

    if (loading) {
        return <div className="text-center mt-10">Laden...</div>;
    }

    if (error) {
        return <div className="text-center mt-10 text-red-500">Fehler: {error}</div>;
    }

    return (
        <div className="container mx-auto mt-10 px-4">
            <h1 className="text-2xl font-bold mb-8 text-center">Offene Aufträge</h1>

            {jobs.length === 0 ? (
                <div className="text-center mt-10 text-gray-500">Keine offenen Aufträge verfügbar.</div>
            ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {jobs.map((job) => (
                    <div key={job.id} className="border rounded-lg shadow-lg bg-white flex flex-col h-full">
                        <div className="p-6 flex-grow">
                            <h3 className="text-lg font-bold mb-4 text-center">{job.language} - {job.industry}</h3>
                            <p><strong>Stadt:</strong> {job.locationDTO?.city || 'Nicht verfügbar'}</p>
                            <p><strong>Adresse:</strong> {job.locationDTO?.address || 'Nicht verfügbar'}</p>
                            <p><strong>Raum:</strong> {job.locationDTO?.room || 'Nicht verfügbar'}</p>
                            <p>
                                <strong>Datum:</strong> {formatDate(new Date(job.date))} Uhr
                            </p>
                            <p><strong>Dauer:</strong> {job.duration || 'Nicht verfügbar'} Minuten</p>
                            {job.additionalInfo !== '' && (
                                <p><strong>Infos:</strong> {job.additionalInfo}</p>
                            )}
                        </div>
                        <div className="mt-auto">
                            <button
                                onClick={() => handleAcceptJob(job)}
                                className="w-full bg-homeTextGradient text-white font-bold py-2 px-4 rounded-b-lg"
                            >
                                Auftrag annehmen
                            </button>
                        </div>
                    </div>
                ))}
                </div>
            )}


            {confirmAcceptOpen && selectedJob && (
                <ConfirmAcceptModal
                    open={confirmAcceptOpen}
                    onConfirm={confirmAcceptJob}
                    onCancel={() => setConfirmAcceptOpen(false)}
                    job={selectedJob}
                    error={acceptError}
                />
            )}
        </div>
    );
}
