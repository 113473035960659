import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {fetchUrl} from "../utils/util";
import {loginBild} from "../assets";
import Navbar from "../componenten/shared/navbar";


function Login() {
    const [credentials, setCredentials] = useState({email: "", password: ""});
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    // Hilfsfunktionen zur besseren Organisation des Codes
    function navigateBasedOnRole(role) {
        setTimeout(() => {
            if (role === "CLIENT") {
                navigate("/dashboard-client");
                window.location.reload();
            } else if (role === "INTERPRETER") {
                navigate("/dashboard-interpreter");
                window.location.reload();
            } else if (role === "ADMIN") {
                navigate("/dashboard-admin");
                window.location.reload();
            } else {
                console.error("Unbekannte Benutzerrolle");
            }
        }, 1000); // Verzögerung hinzugefügt für bessere Handhabung der Navigation
    }

    useEffect(() => {
        const storedToken = localStorage.getItem("authToken");
        const storedRole = localStorage.getItem("role");

        if (storedToken && storedRole) {
            checkToken(storedToken).then((isValid) => {
                if (isValid) {
                    navigateBasedOnRole(storedRole);
                } else {
                    localStorage.clear();
                }
            });
        }
    }, []);


    const checkToken = async (token) => {
        const response = await fetch(fetchUrl + "/api/auth/token", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: token,
        });
        return response.ok;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoginError("");
        setIsLoading(true);

        try {
            const response = await fetch(fetchUrl + "/api/auth/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(credentials),
            });
            if (response.ok) {
                const data = await response.json(); // Erhalten Sie die JSON-Antwort
                localStorage.setItem("authToken", data.token);
                localStorage.setItem("role", data.role); // Speichern Sie die Rolle
                localStorage.setItem("userId", data.userId); // Speichern Sie die Benutzer-ID
                localStorage.setItem("isWhiteListed", data.isWhiteListed); // Speichern Sie die Whitelist
                navigateBasedOnRole(data.role);
            } else {
                await handleErrorResponse(response);
            }
        } catch (error) {
            console.error("Anmeldefehler", error);
            setLoginError("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
        } finally {
            setIsLoading(false);
        }
    };

    async function handleErrorResponse(response) {
        try {
            const errorResponse = await response.json();
            const errorMessage = errorResponse.error || "Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.";
            setLoginError(errorMessage);
        } catch (error) {
            setLoginError("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
        }
        setCredentials({email: "", password: ""});
    }


    return (
        <>
            <Navbar/>
            <div className="flex items-center justify-center p-4" style={{minHeight: 'calc(100vh - 6rem)'}}>
                <div
                    className="flex w-full max-w-5xl bg-white border border-black rounded-lg shadow-lg border-opacity-20">
                    <div className="w-full p-10 md:w-1/2 ">
                        <form onSubmit={handleSubmit} className="space-y-6">
                            {isLoading ? (
                                <div
                                    className="flex items-center justify-center w-full"
                                    style={{height: "300px"}}
                                >
                                    <div className="spinner"></div>
                                </div>
                            ) : (
                                <>
                                    {/* E-Mail-Adresse Eingabefeld */}
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            E-Mail-Adresse
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            value={credentials.email}
                                            onChange={(e) =>
                                                setCredentials({
                                                    ...credentials,
                                                    email: e.target.value,
                                                })
                                            }
                                            placeholder="E-Mail-Adresse"
                                            maxLength={50}
                                            required
                                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="password"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Passwort
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            value={credentials.password}
                                            onChange={(e) =>
                                                setCredentials({
                                                    ...credentials,
                                                    password: e.target.value,
                                                })
                                            }
                                            placeholder="Passwort"
                                            maxLength={50}
                                            required
                                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                        />
                                    </div>
                                    <div>
                                        {loginError && (
                                            <p className="font-semibold text-red-600">{loginError}</p>
                                        )}
                                    </div>
                                    {/* Anmeldebutton */}
                                    <button
                                        type="submit"
                                        className="w-full px-4 py-2 font-bold text-white rounded bg-homeTextGradient"
                                    >
                                        Anmelden
                                    </button>
                                    <p className="!mt-2 text-center text-sm text-black">
                                        Kein Benutzerkonto?{" "}
                                        <a href="/register"> Jetzt Registrieren</a>
                                    </p>
                                </>
                            )}
                        </form>
                    </div>
                    <div
                        className="hidden bg-center bg-cover md:block md:w-[400px]"
                        style={{backgroundImage: `url(${loginBild})`}}
                    ></div>
                </div>
            </div>
        </>
    );
}

export default Login;
