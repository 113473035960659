import React from "react";
import Navbar from "../componenten/shared/navbar";
import Hero from "./(home-page)/hero";
import { videoImg } from "../assets";
import HowItWorks from "./(home-page)/howItWorks.jsx";
import WhoWeServe from "./(home-page)/whoWeServe.jsx";
import Faqs from "./(home-page)/faqs.jsx";
import WhatClientSays from "./(home-page)/whatClientSays.jsx";
import Features from "./(home-page)/features.jsx";
import VideoInterpreting from "./(home-page)/VideoInterpreting";

// TODO: Bilder auswählen, eventuell bessere deisgnarbeit und farbplatte bauen für ganze seiten design überall
function Homepage() {
  return (
    <div className="min-w-0">
      <Navbar />
      <div className="container min-w-0">
        <Hero />

        {/* video here */}
        <div className="scroll-mt-8" id="video">
          <div
            className="relative box-content max-h-[80vh]  w-full"
            style={{ aspectRatio: "2.013986013986014", padding: "40px 0" }}
          >
            <iframe
              src="https://app.supademo.com/embed/cm0z39a1i08zfy2bfqq5jddy2?embed_v=2"
              loading="lazy"
              title="Dolmetschdirekt Demo"
              allow="clipboard-write"
              frameBorder="0"
              webkitAllowFullScreen={true}
              mozAllowFullScreen={true}
              allowFullScreen
              className="absolute top-0 left-0 w-full h-full"
            ></iframe>
          </div>
        </div>
        {/* video here */}

        <VideoInterpreting />
        <HowItWorks />
        <Features />
        <WhoWeServe />
        {/* <WhatClientSays />
        <Faqs /> */}
      </div>
    </div>
  );
}
export default Homepage;
