import React from "react";
import {Link} from "react-router-dom";
import {
    HiOutlineCalendar,
    HiOutlineChartBar,
    HiOutlineLightBulb,
    HiOutlineVideoCamera,
    HiOutlineUserAdd,
    HiUserCircle,
    HiViewList,
    HiOutlineClipboardList,
    HiOutlineSearch
} from "react-icons/hi";
import { HiChatBubbleLeftEllipsis } from "react-icons/hi2";

export default function DashboardInterpreter() {

    // Daten für aktuelle Funktionen
    const currentFeatures = [
        {
            title: "Neue Aufträge",
            description: "Sehen Sie die neusten Aufträge und nehmen Sie sie an.",
            icon: <HiOutlineClipboardList className="text-4xl text-blue-500" />,
            link: "auftraege-anzeigen"
        },
        {
            title: "Meine Aufträge",
            description: "Verwalten Sie Ihre bestätigten Aufträge.",
            icon: <HiViewList className="text-4xl text-green-500" />,
            link: "meine-auftraege"
        },
        {
            title: "Mein Profil",
            description: "Sehen und bearbeiten Sie Ihr Profil.",
            icon: <HiUserCircle className="text-4xl text-purple-500" />,
            link: "profile"
        },
        {
            title: "Feedback senden",
            description: "Teilen Sie uns Ihr Feedback mit.",
            icon: <HiChatBubbleLeftEllipsis className="text-4xl text-yellow-500" />,
            link: "feedback"
        },
        {
            title: "Kollegen einladen",
            description: "Laden Sie Kollegen zur Plattform ein.",
            icon: <HiOutlineUserAdd className="text-4xl text-pink-500" />,
            link: "einladen"
        }
    ];

    // Daten für geplante Funktionen
    const plannedFeatures = [
        {
            title: "Erweiterte Filteroptionen",
            description: "Finden Sie Aufträge mit erweiterten Suchfiltern.",
            icon: <HiOutlineSearch className="text-4xl text-gray-400" />
        },
        {
            title: "Automatische Erinnerungen",
            description: "Erhalten Sie automatische Terminerinnerungen.",
            icon: <HiOutlineCalendar className="text-4xl text-gray-400" />
        },
        {
            title: "Erweiterte Statistiken",
            description: "Analysieren Sie Ihre Leistungen mit detaillierten Berichten.",
            icon: <HiOutlineChartBar className="text-4xl text-gray-400" />
        },
        {
            title: "Kalenderintegration",
            description: "Integrieren Sie Ihre Aufträge in Ihren Kalender.",
            icon: <HiOutlineCalendar className="text-4xl text-gray-400" />
        },
        {
            title: "Video Dolmetschen",
            description: "Bieten Sie Ihre Dienste per Video an.",
            icon: <HiOutlineVideoCamera className="text-4xl text-gray-400" />
        }
    ];

    return (
        <div className="container mx-auto p-4 md:p-10">
            <div className="bg-white shadow rounded-lg p-6">
                <h1 className="text-3xl font-bold text-gray-800 mb-4 text-center">Willkommen zurück!</h1>
                <p className="text-gray-600 text-md mb-8 text-center">
                    Wir freuen uns, Sie wieder bei DolmetschDirekt begrüßen zu dürfen.
                </p>

                {/* Aktuelle Funktionen */}
                <h2 className="text-2xl font-semibold text-gray-800 mb-6">Ihre aktuellen Funktionen</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {currentFeatures.map((feature, index) => (
                        <Link to={feature.link} key={index} className="group hover:no-underline">
                            <div className="bg-gray-50 p-6 rounded-lg shadow hover:bg-blue-50 transition duration-300">
                                <div className="flex items-center justify-center mb-4">
                                    {feature.icon}
                                </div>
                                <h3 className="text-xl font-semibold text-gray-800 mb-2 group-hover:text-blue-600 no-underline">
                                    {feature.title}
                                </h3>
                                <p className="text-gray-600">
                                    {feature.description}
                                </p>
                            </div>
                        </Link>
                    ))}
                </div>

                {/* Geplante Funktionen */}
                <h2 className="text-2xl font-semibold text-gray-800 mt-12 mb-6">Geplante Funktionen</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {plannedFeatures.map((feature, index) => (
                        <div key={index} className="bg-gray-100 p-6 rounded-lg shadow relative">
                            <div className="flex items-center justify-center mb-4">
                                {feature.icon}
                            </div>
                            <h3 className="text-xl font-semibold text-gray-500 mb-2">
                                {feature.title}
                            </h3>
                            <p className="text-gray-500">
                                {feature.description}
                            </p>
                            <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center rounded-lg">
                                <span className="text-gray-700 font-bold">Bald verfügbar</span>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Feedback Aufruf */}
                <div className="mt-12 text-center">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Teilen Sie Ihr Feedback mit uns!</h2>
                    <p className="text-gray-600 mb-6">
                        Ihre Meinungen und Vorschläge helfen uns, DolmetschDirekt zu verbessern.
                    </p>
                    <Link to="feedback" className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
                        Feedback senden
                    </Link>
                </div>
            </div>
        </div>
    );

}
